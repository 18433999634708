$(function () {
    $(document).scroll(function () {
        var $nav = $(".fixed-top");
        var $navRight = $("#rightNav");
        var $logo = $(".logoInitial");
        var $arrow = $(".downArrow");
        $nav.toggleClass('bg-custom', $(this).scrollTop() > 300);
        $logo.toggleClass('logoScroll', $(this).scrollTop() > 350);
        if ($(this).scrollTop() > 300) {
            $navRight.fadeIn();
            $arrow.fadeOut();
        } else {
            $navRight.fadeOut();
            $arrow.fadeIn();
        }
    });

});

//tooltip
$(function () {
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="tooltip"]').on('shown.bs.tooltip', function () {
        $('.tooltip').addClass('animated swing');
    });
});

//image overlay
$(function () {
    var videos = $(".videoWrapper");

    videos.on("click", function () {
        var elm = $(this),
            conts = elm.contents(),
            le = conts.length,
            ifr = null;

        for (var i = 0; i < le; i++) {
            if (conts[i].nodeType == 8) ifr = conts[i].textContent;
        }

        elm.addClass("player").html(ifr);
        elm.off("click");
    });
});

// $('.subpage-banner').each(function () {
//     if($(this)) {

//     }
// });
// var attr = $('.subpage-banner img').attr('src');
var banner = $('.subpage-banner img').attr('src');
if (typeof banner !== typeof undefined && banner !== false) {
    $('.parallax-banner').css('background-image', 'url("' + banner + '")');
}